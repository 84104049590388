import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getAppUsersRequest = () => ({
  type: 'GET_APP_USERS_REQUEST',
})

export const getAppUsersSuccess = (users) => ({
  type: 'GET_APP_USERS_SUCCESS',
  users,
})

export const getAppUsersFailure = (error) => ({
  type: 'GET_APP_USERS_FAILURE',
  error,
})

export const getSingleAppUserRequest = () => ({
  type: 'GET_SINGLE_APP_USER_REQUEST',
})

export const getSingleAppUserSuccess = (user) => ({
  type: 'GET_SINGLE_APP_USER_SUCCESS',
  user,
})

export const getSingleAppUserFailure = (error) => ({
  type: 'GET_SINGLE_APP_USER_FAILURE',
  error,
})

export const deleteAppUserRequest = () => ({
  type: 'DELETE_APP_USER_REQUEST',
})

export const deleteAppUserSuccess = () => ({
  type: 'DELETE_APP_USER_SUCCESS',
})

export const deleteAppUserFailure = (error) => ({
  type: 'DELETE_APP_USER_FAILURE',
  error,
})

export const deleteMultipleAppUsersRequest = () => ({
  type: 'DELETE_APP_USER_REQUEST',
})

export const deleteMultipleAppUsersSuccess = () => ({
  type: 'DELETE_APP_USER_SUCCESS',
})

export const deleteMultipleAppUsersFailure = (error) => ({
  type: 'DELETE_APP_USER_FAILURE',
  error,
})

export const getAppUsers = (filter, page = 1, status = '', search = '') => {
  return (dispatch) => {
    dispatch(getAppUsersRequest())
    let url = URL.BASE_URL + URL.END_POINT.appusers

    let obj = {
      limit: 200,
    }
    if (filter) obj.filter = filter
    if (page) obj.page = page
    if (status !== '') obj.status = status
    if (search !== '') obj.search = search

    Object.keys(obj).forEach((key, i) => {
      if (i === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((users) => {
        dispatch(getAppUsersSuccess(users))
      })
      .catch((error) => {
        dispatch(getAppUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const getSingleAppUser = (id) => {
  return (dispatch) => {
    dispatch(getSingleAppUserRequest())

    fetch(URL.BASE_URL + URL.END_POINT.appusers + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((user) => {
        dispatch(getSingleAppUserSuccess(user))
      })
      .catch((error) => {
        dispatch(getSingleAppUserFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteAppUser = (userId) => {
  return (dispatch) => {
    dispatch(deleteAppUserRequest())

    fetch(URL.BASE_URL + URL.END_POINT.appusers + 'delete-account', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: userId,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteAppUserSuccess())
        toast('User deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteAppUserFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteMultipleAppUsers = (userIds) => {
  return (dispatch) => {
    dispatch(deleteMultipleAppUsersRequest())

    fetch(URL.BASE_URL + URL.END_POINT.appusers + 'delete-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ userIds }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteMultipleAppUsersSuccess(userIds))
        toast('Users deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteMultipleAppUsersFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  appUsers: [],
  loadingAppUsers: false,
  errorAppUsers: null,
}

export const appReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_APP_USERS_REQUEST':
      return {
        ...state,
        loadingAppUsers: true,
        errorAppUsers: null,
      }
    case 'GET_APP_USERS_SUCCESS':
      return {
        ...state,
        appUsers: action.users,
        loadingAppUsers: false,
        errorAppUsers: null,
      }
    case 'GET_APP_USERS_FAILURE':
      return {
        ...state,
        loadingAppUsers: false,
        errorAppUsers: action.error,
      }
    default:
      return state
  }
}

const initialAppUserState = {
  singleAppUser: null,
  loadingSingleAppUser: false,
  errorSingleAppUser: null,
}

export const appUserReducer = (state = initialAppUserState, action) => {
  switch (action.type) {
    case 'GET_SINGLE_APP_USER_REQUEST':
      return {
        ...state,
        loadingSingleAppUser: true,
        errorSingleAppUser: null,
      }
    case 'GET_SINGLE_APP_USER_SUCCESS':
      return {
        ...state,
        singleAppUser: action.user.app_user,
        loadingSingleAppUser: false,
        errorSingleAppUser: null,
      }
    case 'GET_SINGLE_APP_USER_FAILURE':
      return {
        ...state,
        loadingSingleAppUser: false,
        errorSingleAppUser: action.error,
      }
    default:
      return state
  }
}
