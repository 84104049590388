import { useEffect, useRef } from 'react'

function useDebounce(callback, delay) {
  const timer = useRef(null)

  const debounce = (...args) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      callback(...args)
    }, delay)
  }

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  return debounce
}

export default useDebounce
