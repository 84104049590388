import React, { useEffect, useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCloseButton,
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSubscriptionData, getSubscriptionData } from 'src/redux/subscription'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Pagination from 'react-js-pagination'
import { DateTimeConverter } from 'src/utils/DateTimeConverter'
import { AppSpinner } from 'src/components'
import { useTableSelection } from 'src/utils/useTableSelection'

const PackageUsers = () => {
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const dispatch = useDispatch()

  const { subscriptionData, isLoading } = useSelector((state) => {
    return {
      subscriptionData: state.subscription.subscriptionData,
      isLoading: state.subscription.loadingSubscriptionData,
    }
  })

  const { selectedItems, toggleItemSelection, toggleSelectAll, isAllSelected } = useTableSelection(
    subscriptionData?.package,
  )

  useEffect(() => {
    dispatch(getSubscriptionData(search, status, currentPage))
  }, [dispatch, loading, search, status, currentPage])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  async function handleDelete(user_code) {
    setLoading(true)
    try {
      await dispatch(deleteSubscriptionData(user_code))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-light d-flex flex-row align-items-center">
      <CContainer>
        <CRow>
          <CCol sm={12} md={3}>
            <CFormSelect
              size="sm"
              color="info"
              options={[
                { label: 'Status', value: '', disabled: true },
                ...['Pending', 'Accepted', 'Rejected'].map((v) => ({
                  label: v,
                  value: v,
                })),
              ]}
              // value={item.status}
              // onChange={(event) => ApprovedBtn(event.target.value, item.user_code)}
            />
          </CCol>
          <CCol md={5} className="mb-2">
            <CFormInput
              type="text"
              size="sm"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </CCol>
          <CCol md={3} className="mb-2">
            <CFormSelect
              size="sm"
              color="info"
              options={[
                { label: 'Status', value: '', disabled: true },
                ...['Pending', 'Accepted'].map((v) => ({ label: v, value: v })),
              ]}
              value={status}
              onChange={(event) => setStatus(event.target.value)}
            />
          </CCol>
          <CCol md={1} className="mb-2">
            <CCloseButton
              size="sm"
              color="danger"
              onClick={() => {
                setSearch('')
                setStatus('')
              }}
            />
          </CCol>
        </CRow>
        {isLoading ? (
          <CContainer className="d-flex justify-content-center align-items-center">
            <AppSpinner />
          </CContainer>
        ) : (
          <>
            <CRow className="justify-content-center mb-2">
              <CCol md={12} lg={12} xl={12}>
                <PerfectScrollbar style={{ height: '100%' }}>
                  <CTable align="middle" className="mb-0 border" hover responsive>
                    <CTableHead color="light">
                      <CTableRow>
                        <CTableHeaderCell>
                          <CFormCheck
                            checked={isAllSelected}
                            onChange={toggleSelectAll}
                            color="primary"
                          />
                        </CTableHeaderCell>
                        <CTableHeaderCell>Package</CTableHeaderCell>
                        <CTableHeaderCell>Email</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Amount</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Duration</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Start Date</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Expiry Date</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Approval Date</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Transaction Id</CTableHeaderCell>
                        <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    {subscriptionData?.package?.length > 0 && (
                      <CTableBody>
                        {subscriptionData?.package?.map((item, index) => (
                          <CTableRow v-for="item in tableItems" key={index}>
                            {/* <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <small className="text-medium-emphasis">{index + 1}) </small>
                          </div>
                        </CTableDataCell> */}
                            <CTableDataCell>
                              <CFormCheck
                                checked={selectedItems.includes(item.id)}
                                onChange={() => toggleItemSelection(item.id)}
                                color="primary"
                              />
                            </CTableDataCell>
                            <CTableDataCell>
                              <div className="">
                                <strong>{item.package_name}</strong>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>
                              <div className="">
                                <strong>{item.email_id}</strong>
                              </div>
                            </CTableDataCell>

                            <CTableDataCell className="text-center">
                              <div className="clearfix">
                                <h6 className="">{item.package_amount}</h6>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <div className="clearfix">
                                <h6 className="">{item.package_duration}</h6>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <div className="clearfix">
                                <h6 className="">{DateTimeConverter(item.start_date)}</h6>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <div className="clearfix">
                                <h6 className="">{DateTimeConverter(item.expiry_date)}</h6>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <div className="clearfix">
                                <h6 className="">{item.status}</h6>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <div className="clearfix">
                                <h6 className="">{DateTimeConverter(item.createdAt)}</h6>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <div className="clearfix">
                                <h6 className="">{item.transaction_id}</h6>
                              </div>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CButtonGroup role="group" aria-label="Basic mixed styles example">
                                <CButton
                                  color="danger"
                                  size="sm"
                                  onClick={() => handleDelete(item.user_code)}
                                >
                                  <CIcon icon={cilTrash} className="text-white" />
                                </CButton>
                              </CButtonGroup>
                            </CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    )}
                  </CTable>
                </PerfectScrollbar>
                {subscriptionData?.package?.length === 0 && (
                  <h3 className="text-center">Data Not Found</h3>
                )}
              </CCol>
            </CRow>
            {subscriptionData?.package?.length > 0 && (
              <ul className="pagination justify-content-center">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={subscriptionData?.total}
                  hideFirstLastPages={false}
                  onChange={handlePageChange}
                  pageRangeDisplayed={5}
                  nextPageText="Next"
                  prevPageText="Previous"
                  lastPageText="Last"
                  firstPageText="1st"
                  itemClass="page-item"
                  linkClass="page-link"
                  activeClass="active"
                  activeLinkClass="active"
                  hideDisabled={false}
                />
              </ul>
            )}
          </>
        )}
      </CContainer>
    </div>
  )
}

export default PackageUsers
